<template>
  <div
    class="justify-content-between align-items-center content-sidebar px-1 py-1 modal-bottom modal-top"
  >
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.Type')"
            label-for="type"
          >
            <b-form-select
              v-model="selected"
              id="authentication-type"
              :disabled="true"
            >
              <b-form-select-option :value="null">Excel</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.NameConection')"
            label-for="name"
            :invalid-feedback="invalidNameState ? $t('NameInvalid') : $t('RequiredField')"
          >
            <b-form-input
              id="name"
              v-model="name"
              placeholder="ERP ambiente de produção"
              :state="stateName"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          class="d-flex justify-content-end save-button"
        >
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              variant="primary"
              class="form-control"
              @click="createConnection()"
            >
              {{ $t('IntegrationPage.Save') }}
            </b-button>

            <b-button
              v-if="sendingForm"
              variant="primary"
              disabled
              class="form-control loading"
            >
              <b-spinner small />
              {{ $t('IntegrationPage.Sending') }} ...
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { TheMask } from 'vue-the-mask';

  import {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BBadge
  } from 'bootstrap-vue';

  export default {
    components: {
      BAvatar,
      BListGroup,
      BListGroupItem,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BFormSelectOption,
      BButton,
      BSpinner,
      BRow,
      BCol,
      BFormTextarea,
      BBadge,
      TheMask,
      flatPickr,
      ToastificationContent
    },
    directives: {
      Ripple
    },
    props: {
      connectionId: {
        type: String
      },
      listagemContexto: {
        type: Array
      }
    },
    mounted() {
      this.getConnectionById(this.connectionId);
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        type: 'CSV',
        name: '',
        datasource: '',
        database: '',
        user: '',
        password: '',
        integratedSecurity: 0,
        sendingForm: false,
        selected: null,
        invalidNameState: null,
        stateName: null,
        contexto: []
      };
    },
    methods: {
      validaNomeFonte() {
        if (this.name != this.contexto.name) {
          let fonteExistente = this.listagemContexto.filter(
            (item) => item.name == this.name && item.type_id == '4'
          );
          if (fonteExistente.length > 0) {
            return true;
          }
          return false;
        }
      },
      validaCampos() {
        this.stateName = this.name && !this.validaNomeFonte() ? null : false;
        this.invalidNameState = this.validaNomeFonte();
      },
      getConnectionById(id) {
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/connection/${id}?site_guid=${this.currentSite}`
          )
          .then((response) => {
            this.name = response.data.name;
            this.contexto = response.data;
          })
          .catch((error) =>
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
          );
      },
      createConnection() {
        this.validaCampos();
        if (this.stateName != false) {
          this.sendingForm = true;
          this.$http
            .post(`/api/${this.$router.currentRoute.params.prefix}/connection`, {
              id: this.connectionId,
              type: this.type,
              name: this.name,
              site_guid: this.currentSite
            })
            .then((response) => {
              this.sendingForm = false;
              this.$emit('refetch-data');
              this.$emit('show-message');
            })
            .catch((error) => {
              this.sendingForm = false;
              this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
            });
        }
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      }
    }
  };
</script>
<style lang="scss">
  .btn.loading {
    width: auto !important;
  }
</style>
