<template>
  <div class="justify-content-between align-items-center px-1 py-1 modal-bottom modal-top">
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group :label="$t('IntegrationPage.Type')" label-for="type">
            <b-form-select
              v-model="typeConnection"
              id="authentication-type"
              :options="listagemTiposConexoes"
              @input="changeModal()"
            >
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.NameConection')"
            label-for="name"
            :invalid-feedback="invalidNameState ? $t('NameInvalid') : $t('RequiredField')"
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('IntegrationPage.ERP')"
              :state="stateName"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.Server')"
            label-for="datasource"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              placeholder="127.0.0.1"
              id="datasource"
              v-model="datasource"
              :state="stateDataSource"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.Database')"
            label-for="database"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              v-model="database"
              id="database"
              :placeholder="$t('TypeHere')"
              :state="stateDatabase"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.IntegratedSecurity')"
            label-for="integrated-security"
          >
            <b-form-checkbox
              switch
              :checked="true"
              @change="resetUserAndPasswordField()"
              v-model="integratedSecurity"
              id="integrated-security"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.Login')"
            label-for="user"
            v-if="!integratedSecurity"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              v-model="user"
              id="user"
              :placeholder="$t('TypeHere')"
              :state="stateUser"
              autocomplete="no-autocomplete"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.Password')"
            label-for="password"
            v-if="!integratedSecurity"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-input-group :class="{ 'is-invalid': statePassword === false }">
              <b-form-input
                class="border-right-0"
                :type="passwordType"
                v-model="password"
                id="password"
                placeholder="******"
                :state="statePassword"
                autocomplete="new-password"
              />
              <b-input-group-append is-text style="cursor: pointer" @click="showPassword">
                <eye v-if="passwordType === 'password'" />
                <eye-off v-else />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="12" class="d-flex justify-content-end save-button">
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              variant="primary"
              class="form-control"
              @click="createConnection()"
            >
              {{ $t('IntegrationPage.Save') }}
            </b-button>

            <b-button v-if="sendingForm" variant="primary" disabled class="form-control loading">
              <b-spinner small />
              {{ $t('IntegrationPage.Sending') }} ...
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { TheMask } from 'vue-the-mask';
  import Eye from '@/assets/images/icons/eye.svg';
  import EyeOff from '@/assets/images/icons/eye-off.svg';

  import {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend
  } from 'bootstrap-vue';

  export default {
    components: {
      BAvatar,
      BListGroup,
      BListGroupItem,
      BCardText,
      BForm,
      BFormGroup,
      BFormCheckbox,
      BFormInput,
      BFormSelect,
      BFormSelectOption,
      BButton,
      BSpinner,
      BRow,
      BCol,
      BFormTextarea,
      TheMask,
      flatPickr,
      ToastificationContent,
      BInputGroup,
      BInputGroupAppend,
      Eye,
      EyeOff
    },
    directives: {
      Ripple
    },
    props: {
      listagemContexto: {
        type: Array
      }
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        type: 'SQL Server',
        name: '',
        datasource: '',
        database: '',
        user: '',
        password: '',
        stateName: null,
        stateDataSource: null,
        stateDatabase: null,
        stateUser: null,
        statePassword: null,
        invalidNameState: null,
        integratedSecurity: true,
        sendingForm: false,
        checked: true,
        typeConnection: 2,
        listagemTiposConexoes: [
          { text: 'REST', value: 1 },
          { text: 'SQL Server', value: 2 },
          { text: 'SAP (RFC)', value: 3 },
          { text: 'Excel (.csv)', value: 4 },
          { text: 'Azure Data Factory', value: 5 }
        ],
        passwordType: 'password'
      };
    },
    methods: {
      validaNomeFonte() {
        let fonteExistente = this.listagemContexto.filter(
          (item) => item.name == this.name && item.type_id == '2'
        );
        if (fonteExistente.length > 0) {
          return true;
        }
        return false;
      },
      validaCampos() {
        this.stateName = this.name && !this.validaNomeFonte() ? null : false;
        this.stateDataSource = this.datasource ? null : false;
        this.stateDatabase = this.database ? null : false;
        this.stateUser = null;
        this.statePassword = null;
        this.invalidNameState = this.validaNomeFonte();
        if (!this.integratedSecurity) {
          this.stateUser = this.user ? null : false;
          this.statePassword = this.password ? null : false;
        }
      },
      createConnection() {
        this.validaCampos();
        if (
          !this.integratedSecurity
            ? this.stateUser != false &&
              this.statePassword != false &&
              this.stateName != false &&
              this.stateDataSource != false &&
              this.stateDatabase != false
            : this.stateName != false &&
              this.stateDataSource != false &&
              this.stateDatabase != false
        ) {
          this.sendingForm = true;
          this.$http
            .post(`/api/${this.$router.currentRoute.params.prefix}/connection`, {
              id: -1,
              type: this.type,
              name: this.name,
              datasource: this.datasource,
              database: this.database,
              user: this.user,
              password: this.password,
              integratedSecurity: this.integratedSecurity,
              site_guid: this.currentSite
            })
            .then((response) => {
              this.sendingForm = false;
              this.$emit('refetch-data');
              this.$emit('show-message');
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('IntegrationPage.createConectionSuccess'),
                'success'
              );
            })
            .catch((error) => {
              this.sendingForm = false;
              this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
            });
        }
      },
      resetUserAndPasswordField() {
        if (this.integratedSecurity !== 'True') {
          this.user = '';
          this.password = '';
        }
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      changeModal() {
        this.$emit('changeModal', this.typeConnection);
      },
      showPassword() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      }
    }
  };
</script>

<style lang="scss">
  .form-control.is-invalid {
    background-image: none !important;
  }

  .btn.loading {
    width: auto !important;
  }

  #authentication-type {
    background: url('../../../../assets/images/pages/arrow-down-2.png') right 1rem center/10px 10px
      no-repeat;
  }
</style>
